const fa = {
	common: {
		copy: "کپی",
		copied: "کپی شد",
		ok: "باشه!",
		cancel: "لغو",
		back: "برگشت",
		metaOraPlus: "MetaOra Plus",
		continue: "ادامه",
		no: "خیر",
		yes: "بله",
		title: "عنوان",
		email: "آدرس ایمیل",
		password: "رمز عبور",
		phoneNumber: "شماره تلفن همراه",
		newPassword: "رمز عبور جدید",
		confirmNewPassword: "تایید رمز عبور جدید",
		confirmPassword: "تکرار رمز عبور",
		fullname: "نام و نام خانوادگی",
		and: "و",
		or: "یا",
		send: "ارسال",
		here: "اینجا",
		login: "ورود به اپلیکیشن",
		signup: "ثبت نام",
		submit: "ثبت",
		amount: "{{amount}} تومان",
		addImage: "افزودن تصویر",
		addNewNote: "افزودن لغت یا اصطلاح جدید",
		editNote: "ویرایش لغت یا اصطلاح",
		dictionary: "دیکشنری",
		nextUnitAccess: "مجوز دسترسی به بخش بعدی را ندارید.",
		of: "از",
		premium: "حرفه‌ای",
		free: "رایگان",
		all: "همه",
		priceUsd: "{{amount}} دلار",
		priceTl: "{{amount}} لیر",
		priceToman: "{{amount}} تومان",
	},

	commonErrors: {
		unknown: "اشتباهی رخ داده است.",
		tryAgain: "اشتباهی رخ داده است. لطفا بعدا مجددا تلاش نمایید.",
		invalidEmail: "ایمیل نامعتبر است",
	},

	signupScreen: {
		welcome: "به متااورا خوش آمدید!",
		apple: "ثبت نام با اپل",
		google: "ثبت نام با گوگل",
		phone: "ثبت نام با تلفن همراه",
		login: "ورود به اپلیکیشن",
		loginMsg: "قبلا ثبت نام نموده اید؟",
		email: "ثبت‌ نام با ایمیل",
	},
	signupForm: {
		submit: "ساخت حساب کاربری",
		termsMsg: "با ایجاد حساب کاربری شما با",
		termOfService: "شرایط استفاده از خدمات",
		privacy: "قوانین ما",
		agree: "موافقت می نمایید.",
	},
	validation: {
		maxLength: "فیلد باید حداکثر %{count} کاراکتر باشد",
		minLength: "فیلد باید حداقل %{count} کاراکتر باشد",
		min: "حداقل مقدار این فیلد %{count} می باشد",
		max: "حداکثر مقدار این فیلد %{count} می باشد",
		required: "%{field} اجباری می باشد.",
		required2: "این فیلد اجباری است.",
		email: "لطفا یک ایمیل معتبر وارد نمایید.",
		string: "این فیلد باید به صورت رشته باشد.",
		password:
			"رمز عبور باید حداقل دارای یک حرف بزرگ، یک حرف کوچک و یک عدد باشد.",
		length: "این فیلد باید دارای %{count} کاراکتر باشد.",
		justNumber: "لطفا فقط از اعداد استفاده نمایید.",
		passwordMatch: "این رمز عبور با رمز عبور فیلد قبل مطابقت ندارد.",
		numberStepper: "این عدد باید در بازه %{min} و %{max} باشد.",
	},
	loginScreen: {
		pageTitle: "ورود به اپلیکیشن",
		registerMsg: "ثبت نام نکرده اید؟",
		register: "ثبت نام",
		apple: "Sign In with Apple",
		google: "Sign In with Google",
		phone: "ورود با تلفن همراه",
		submit: "ورود به اپلیکیشن",
		email: "ورود با ایمیل",
	},
	loginForm: {
		submit: "ورود",
		forgetMsg: "رمز عبور خود را فراموش کرده اید؟",
		forgetLink: "پاکسازی",
	},
	phoneScreen: {
		signup: {
			pageTitle: "ثبت نام با تلفن همراه",
		},
		enterPhoneNumber: "لطفا تلفن همراه خود را وارد نمایید:",
		createAccount: "ایجاد حساب کاربری",
		submit: "ارسال کد تایید",
		signin: {
			pageTitle: "ورود با تلفن همراه",
		},
	},
	verifyCodeScreen: {
		pageTitle: "کد تایید",
		title: "لطفا کد تایید را وارد نمایید",
		subtitle:
			"شما فقط ۲ دقیقه زمان برای تایید دارید. اگر کد تاییدی دریافت نکرده اید، می توانید بعد از ۲ دقیقه دوباره تلاش نمایید.",
		sendAgain: "ارسال مجدد",
		invalidVerifyCode: "کد تایید نامعتبر است!",
		invalidVerifyType: "نوع تایید نامعتبر است!",
		verifyDirectAccess: "فرآیند تایید به صورت مستقیم قابل دسترس نمی باشد.",
	},
	forgetPasswordScreen: {
		pageTitle: "رمز عبور خود را فراموش کرده اید؟",
		title: "جایی برای نگرانی نیست!",
		subtitle:
			"گاها همچین مسايلی پیش آید. لطفا ایمیل مربوط به حساب کاربری خود را وارد نمایید.",
		submit: "ارسال",
		sentMsg:
			"یک ایمیل برای شما ارسال شد. این ایمیل حاوی یک لینک جهت بازیابی رمز عبور شماست.",
	},
	walkthroughScreen: {
		submit: "همین الان شروع کن و لذت ببر!",
		slide1Title: "فراتر از مرزهای یادگیری",
		slide1Msg:
			"به دنیای جدید یادگیری متااورا خوش آمدید: مسیر متمایز یادگیری زبان ها که ما به طور خاص برای شما طراحی می کنیم.",
		slide2Title: "درس های مطابق بر علاقه شما",
		slide2Msg:
			"اکنون، نوبت شماست که موضوع دوره خود را انتخاب کنید. در متااورا، واژگان، عبارات، تلفظ ها و الگوهای دستور زبان را از طریق چندین دوره با موضوعات مختلف یاد خواهید گرفت. آن را امتحان کنید تا متوجه کارایی آن شوید.",
		slide3Title: "شبیه بازی و سرگرمی",
		slide3Msg:
			"افراد رقابتی باهوش یا کسانی که به دنبال جنبه سرگرم کننده هر چیزی هستند، تجربه یادگیری بسیار خوبی در اینجا خواهند داشت. هر قدمی که برمی دارید، هر پیشرفتی که می کنید، متااورا پاداشی برای تشویق دستاورد شما دارد.",
		slide4Title: "مناسب تمامی علاقمندان",
		slide4Msg:
			"در متااورا، همه می توانند دانش خود را، از یک سطح کاملا مبتدی به یک زبان آموز پیشرفته مسلط، برسانند.",
		slide5Title: "سریعتر یاد بگیرید، عمیق تر درک کنید و هرگز فراموش نکنید.",
		slide5Msg:
			"هوش مصنوعی متااورا یک تجربه یادگیری متفاوت را بر اساس چرخه خاصی از مرور و تمرین‌ها ارائه می‌کند که باعث یادگیری سریع، عمیق و فراموش نشدنی می‌شود.",
	},
	resetPassword: {
		pageTitle: "بازیابی رمز عبور",
		title: "ایجاد رمز عبور جدید",
		submit: "تغییر رمز عبور",
		resetMsg:
			"رمز عبور شما تغییر یافته است و شما می توانید از طریق این لینک وارد شوید",
	},
	selectAffiliateScreen: {
		pageTitle: "معرف خود را انتخاب کنید!",
		subheading:
			"معرف خود را انتخاب کنید. شما نمی توانید بعدا این گزینه را تنظیم نمایید، بنابراین هم اینک معرف خود را با کد و یا عنوان آنها جستجو کرده و انتخاب نمایید",
		selectAffiliate: "انتخاب از لیست سایر معرفان",
		submit: "ثبت",
		searchPlaceholder: "جستجو",
		confirm: "تایید",
		cancel: "لغو",
	},
	affiliateScreen: {
		heading: "همکار فروش ما شوید!",
		updateHeading: "ویرایش عنوان همکاری در فروش",
		pageTitle: "همکاری در فروش",
		subHeading:
			"لطفا یک عنوان وارد نمایید. کاربرانی که توسط شما معرفی می شوند می توانند از طریق جستجوی عنوان و یا کد همکاری در فروش شما را پیدا نمایند",
		enterTitle: "عنوان برند، آموزشگاه، نام مستعار ...",
		affiliateStatsHeader: "آمار همکاری در فروش",
		affiliateTitle: "عنوان همکاری در فروش",
		affiliateStatus: "وضعیت همکاری در فروش",
		totalEarnings: "کل درآمد",
		totalReferrals: "کل ارجاعات خرید",
		totalInvitedUsers: "کل کاربران دعوت‌شده",
		withdrawalAmount: "مبلغ برداشت",
		commission: "کمیسیون",
		affiliateCode: "کد همکاری در فروش",
		affiliateLink: "لینک همکاری در فروش",
		affiliateMenuHeader: "منوی همکاری در فروش",
		bankInformation: "اطلاعات بانکی",
		paymentRequests: "درخواست‌های پرداخت",
		withdrawalList: "لیست برداشت",
		pending: "معلق",
		inactive: "غیرفعال",
		active: "فعال",
		referrals: "لیست ارجاعات",
		invitedUsers: "لیست کاربران دعوت شده",
		balance: "مانده حساب",
		noBody: "ندارم",
	},
	affiliateBankInfoScreen: {
		pageTitle: "اطلاعات بانکی",
		heading: "اطلاعات بانکی شما",
		subHeading: "لطفا اطلاعات بانکی خود را وارد کنید",
		submit: "ثبت",
		accountHolderLabel: "نام صاحب حساب",
		accountHolder: "نام صاحب حساب",
		bankNameLabel: "نام بانک",
		bankName: "نام بانک",
		IBANNumberLabel: "شماره شبا",
		IBANNumber: "شماره شبا",
		cardNumberLabel: "شماره کارت",
		cardNumber: "شماره کارت",
		successMsg: "اطلاعات بانکی شما با موفقیت ذخیره شد.",
	},
	paymentRequestScreen: {
		pageTitle: "لیست درخواست وجه",
		addBtn: "درخواست جدید",
		infoMsg: "حداقل میزان درخواست وجه %{amount} می باشد.",
		emptyList: "شما هیچ درخواست وجهی ندارید.",
		status: "وضعیت درخواست",
		payDateReq: "تاریخ درخواست",
		amount: "وجه درخواستی",
		pending: "در حال بررسی",
		approved: "تایید شده",
		rejected: "رد شده",
	},
	paymentRequestNewScreen: {
		pageTitle: "درخواست جدید وجه",
		heading: "درخواست جدید وجه خود را ثبت نمایید",
		subHeading:
			"لطفا حداقل میزان درخواستی و مانده حساب خود را مد  نظر قرار دهید. همچنین از کیبورد انگلیسی استفاده کنید.",
		submit: "ثبت",
		requestedAmountLabel: "مبلغ درخواستی",
		requestedAmount: "مبلغ درخواستی",
		successMsg: "درخواست شما با موفقیت ثبت گردید.",
	},
	withdrawScreen: {
		pageTitle: "وجوه برداشت شده",
		emptyList: "تا کنون پرداختی به شما صورت نگرفته است.",
		paymentAmount: "مبلغ واریزی",
	},
	ReferralsScreen: {
		pageTitle: "لیست ارجاعات",
		emptyList: "هیچ ارجاعی وجود ندارد.",
		yourBenefit: "سود شما",
		id: "شناسه کاربری",
		productPrice: "قیمت محصول",
		commission: "کمیسیون",
	},
	invitedUsersScreen: {
		pageTitle: "لیست کاربران دعوت شده",
		emptyList: "هیچ کاربری دعوت نشده است.",
		refBuyCount: "تعداد خریدها",
		id: "شناسه کاربری",
	},
	subscriptionPlanScreen: {
		pageTitle: "خرید اشتراک",
		couponCodeLabel: "کد تخفیف",
		couponCodeRequired: "لطفا کد تخفیف خود را وارد نمایید.",
		couponCodeLength: "طول کد تخفیف باید ۶ کاراکتر باشد.",
		couponCodePlaceholder: "کد تخفیف را وارد نمایید",
		apply: "اعمال",
		payment: "پرداخت",
		planPrice: "هزینه اشتراک",
		discount: "تخفیف",
		finalPrice: "قیمت نهایی",
		emptyList: "هیچ اشتراک فعالی در حال حاضر وجود ندارد",
	},
	latestCourses: {
		header: "جـدیـدتـریـن دوره هـا",
	},
	home: {
		featuredCourses: "دوره های برگزیده",
		lastStudy: "درس در حال مطالعه",
		discountDay: "تخفیف روز",
	},
	sideMenu: {
		dictionary: "دیکشنری",
		helpCenter: "پشتیبانی",
		reportBug: "گزارش خطا",
		sendFeedback: "ارسال نظر و فیدبک",
		policy: "قوانین و سیاست‌ها",
		terms: "شرایط عمومی استفاده از سرویس",
		logout: "خروج",
		logoutAll: "خروج از تمام دستگاه‌ها",
		slogan: "فراتر از مرزهای یادگیری",
		version: "با اپیکیشن متااورا (MetaOra), نسخه",
		goToAdmin: "قسمت مدیریت",
		planning: "برنامه ریزی مطالعه",
		home: "صفحه اصلی",
		courses: "دوره‌ها",
		affiliate: "همکاری در فروش و کسب درآمد",
		review: "مرور درس‌ها",
		metaOraPlus: "خرید نسخه حرفه‌ای متااورا!",
		metaOraPlusRenew: "تمدید نسخه حرفه‌ای متااورا",
		copyright: "مالکیت فکری",
	},
	components: {
		heading: {
			viewAll: "مشاهده همه",
		},
	},
	englishLevel: {
		A0: "کاملا مبتدی",
		A1: "مبتدی",
		A2: "مقدماتی",
		B1: "متوسط",
		B2: "بالاتر از متوسط",
		C1: "پیشرفته",
		C2: "حرفه‌ای",
		C3: "به سمت نیتیو",
	},
	courses: {
		all: "همه دوره‌ها",
		assigned: "دوره‌های واگذار شده",
		yours: "دوره های شما",
	},
	lesson: {
		watchEnjoy: "ببین و لذت ببر",
		videoStep: "ویدیو",
		videoStepDesc: "در قدم اول گوش کن و بخون!",
		watchBtn: "مشاهده",
		vocabularyNotes: "لغات و اصطلاحات",
		vocabulary: "تمرین لغت",
		vocabularyDesc: "دامنه لغاتتو گسترش بده!",
		orderGame: "بازی با کلمات",
		playWithWords: "جمله بساز و مهارت شنواییت رو بهتر کن",
		speaking: "تمرین صحبت کردن",
		speakingDesc: "مهارت گفتاریت رو تقویت کن",
		write: "تمرین نوشتن",
		writeDesc: "مهارت نوشتاریت رو تقویت کن",
		currUnit: "بخش کنونی → %{unit}",
		nextUnit: "قفل بخش بعد رو باز کن",
		IdontKnow: "بلد نیستم",
		IKnow: "بلدم",
		vocabListStep: "لیست لغات و اصطلاحات",
		vocabListStepDesc: "مرور لغات و اصطلاحات این درس!",
		letsSee: "مشاهده",
	},
	note: {
		submitNote: "ذخیره",
		expressionOrWord: "عبارت یا کلمه",
		wordsSelectorMsg: "حداقل یک کلمه باید انتخاب گردد",
		noteType: "نوع لغت یااصطلاح",
		noteTypeMsg: "لطفا نوع لغت یااصطلاح را انتخاب نمایید.",
		definition: "توضیح",
		emptyFieldMsg: "این فیلد نمی تواند خالی باشد.",
		definitionPlaceholder: "لطفا یک توضیح به یادماندنی وارد نمایید.",
		example: "مثال",
		examplePlaceholder: "درج یک مثال",
		noteImg: "تصویر لغت یا اصطلاح",
		baseForm: "شکل مصدر یا پایه",
		baseFormPlaceholder: "شکل مصدر یا پایه را وارد نمایید",
		newNote: "جدید",
		fromLib: "از کتابخانه",
		searchNote: "جستجوی لغت یا اصطلاح (به انگلیسی)",
		removeConfirmTitle: "حذف لغت یا اصطلاح",
		removeConfirmMessage:
			"آیا مطمئنید می خواهید این لغت یا اصطلاح راحذف نمایید؟",
	},
	profile: {
		deleteAccount: "حذف حساب کاربری",
		logoutAll: "خروج از همه دستگاه‌ها",
		logout: "خروج",
		policy: "قوانین و مقررات",
		terms: "شرایط عمومی استفاده از سرویس",
		metaOraPlus: "خرید نسخه حرفه‌ای متااورا!",
		metaOraPlusRenew: "تمدید نسخه حرفه‌ای متااورا",
		metaOraPlusAppStore:
			"خرید نسخه حرفه‌ای از اپ‌استور (کاربران خارج ایران)",
		metaOraPlusGooglePlay:
			"خرید نسخه حرفه‌ای از گوگل‌ پلی (کاربران خارج ایران)",
		changePassword: "تغییر رمز عبور",
		affilate: "همکاری در فروش و کسب درآمد",
		learningLanguage: "زبان درحال یادگیری",
		nativeLanguage: "زبان مادری",
		username: "نام کاربری",
		profile: "پروفایل",
		pageTitle: "حساب کاربری شما",
		phoneNumber: "شماره تلفن",
		others: "سایر موارد",
		subscriptionPlanTitle: "اشتراک",
		subscriptionStart: "تاریخ شروع اشتراک",
		subscriptionEnd: "تاریخ پایان اشتراک",
		subscriptionStatus: "وضعیت اشتراک",
		subscriptionStatus_active: "فعال",
		subscriptionStatus_expired: "منقضی شده",
		role_subscriber: "زبان آموز",
		role_author: "تیچر",
		role_admin: "مدیریت",
		subscription_free: "نسخه رایگان",
		subscription_premium: "نسخه حرفه‌ای",
		settings: "تنظیمات",
		textToSpeech: "متن به صدا",
		notifications: "اطلاع رسانی‌ها",
	},
	review: {
		pageTitle: "مـرور درس‌هـا",
		emptyListMsg: "درسی برای مرور وجود ندارد!",
		pageSingleTitle: "مرور",
	},
	quiz: {
		checkAnswer: "چک کردن جواب",
		play: "پخش",
		saveNext: "ذخیره و برو بعدی",
		saveEnd: "ذخیره و پایان",
		easily: "به آسونی",
		withhesitation: "با تردید",
		withDifficulty: "به سختی",
		notTooHard: "متوسط",
		hard: "سخت",
		reallyHard: "خیلی سخت",
		ICouldAnswer: "چطور جواب دادی؟",
		theQuestionWas: "سوال چطور بود؟",
	},
	orderGame: {
		pageTitle: "بـازی بـا کلمـات",
		next: "بعدی",
		end: "پایان",
		play: "پخش",
		endUnit: "ذخیره و اتمام",
	},
	speaking: {
		pageTitle: "صحبت کن و مقایسه کن",
	},
	player: {
		hideSubtitle: "زیرنویس پنهان شده است.",
	},
	vocabulary: {
		pageTitle: "تمرین لغات و اصطلاحات",
	},
	writing: {
		pageTitle: "تمرین نوشتن",
	},
	achievement: {
		resultTitle: "نتایج و دستیابی‌ها",
		unitDoneTitle: "این بخش کامل شده است!",
		dailyGoal: "به هدفتون رسیده اید.",
		next: "برو بعدی",
		retry: "تلاش مجدد",
		redirectMsg: "در حال انتقال به نتایج بخش!",
		failedMsg: "متاسفانه مردود شدید!",
		successMsg: "شما %{count} ستاره بدست آوردید!",
	},
	mediaLesson: {
		watchAgain: "مشاهده مجدد ویدیو!",
		goPractice: "شروع تمرینات",
		congrat: "تبریک!",
		inProgress: "در حال پیشرفت",
		complete: "درس تکمـیــل شــد!",
		learnedWords: "شما %{count} کلمه یاد گرفته اید.",
	},
	streak: {
		countTitle: "%{count} زنجیره مطالعه",
		reachedGoalMsg: "شما به هدف روزانه خود رسیده‌اید.",
		watchVideo: "مشاهده ویدیو",
		nextUnit: "بخش بعدی",
		unlockNextUnit: "باز کردن قفل بخش بعدی",
		lessonList: "برو به لیست درس‌ها",
		redirectMsg: "در حال انتقال به هدف روزانه...",
	},
	changePassword: {
		title: "ایجاد رمز عبور جدید",
		submit: "تغییر رمز",
		old: "رمز قدیمی",
		new: "رمز جدید",
		confirmMsg: "لطفا رمز عبور خود را تکرار و تایید کنید.",
		notMatch: "این دو رمز عبور با یکدیگر مطابقت ندارند.",
		confirm: "تکرار رمز",
		pageTitle: "تغییر رمز عبور",
	},
	helpCenter: {
		pageTitle: "مرکز پشتیبانی",
		title: "تماس با خدمات پشتیبانی",
		whatsAppSupport: "پشتیبانی از طریق واتساپ",
		telegramSupport: "پشتیبانی از طریق تلگرام",
		instagramSupport: "پشتیبانی از طریق اینستاگرام",
		emailSupport: "پشتیبانی از طریق ایمیل",
		supportMsg:
			"لطفا از لینک‌های بالا جهت ارتباط با پشتیبانی استفاده نمایید.",
		defMessage: "درود من نیاز به کمک دارم بابت ...",
		emailSubject: "پشتیبانی",
		signupProblem:
			"درود، من در روند تکمیل ثبت نام با خطا متوجه شدم. شناسه کاربری من %{userId} می باشد",
	},
	reportBug: {
		pageTitle: "گزارش خطا",
		title: "گزارش خطا به پشتیبانی",
		whatsAppSupport: "گزارش از طریق واتساپ",
		telegramSupport: "گزارش از طریق تلگرام",
		instagramSupport: "گزارش از طریق اینستاگرام",
		emailSupport: "گزارش از طریق ایمیل",
		supportMsg:
			"لطفا از لینک‌های بالا جهت گزارش خطا به پشتیبانی استفاده نمایید.",
		defMessage: "درود، من قصد گزارش یک خطا به پشتیبانی را دارم...",
		emailSubject: "گزارش خطا",
	},
	sendFeedback: {
		pageTitle: "ارسال نظر و فیدبک",
		title: "ارسال نظر به پشتیبانی مشتریان",
		whatsAppSupport: "ارسال نظر از طریق واتساپ",
		telegramSupport: "ارسال نظر از طریق تلگرام",
		instagramSupport: "ارسال نظر از طریق اینستاگرام",
		emailSupport: "ارسال نظر از طریق ایمیل",
		supportMsg:
			"لطفا از لینک‌های بالا جهت ارسال نظر به پشتیبانی استفاده نمایید.",
		defMessage: "درود، من قصد نظر به پشتیبانی را دارم...",
		emailSubject: "ارسال نظر و فیدبک",
	},
	termsOfService: {
		pageTitle: "شرایط استفاده از خدمات",
	},
	privacyPolicy: {
		pageTitle: "قوانین و سیاست‌ها",
	},
	notification: {
		error: "خطا!",
	},
	userPremiumPage: {
		pageTitle: "محدودیت دسترسی",
		title: "دسترسی مخصوص نسخه حرفه‌ای",
		backToHome: "بازگشت به صفحه‌اصلی",
		accessMsg:
			"فقط کاربرانی که دارای اشتراک فعال هستند، می توانند به نسخه حرفه‌ای دسترسی پیدا کنند.",
	},
	premiumBanner: {
		title: "خرید نسخه حرفه‌ای",
		subtitle: "دسترسی نامحدود به محتوا و ویژگی ها",
		btn: "خرید",
		backToHome: "بازگشت به صفحه‌اصلی",
	},
	accessPage: {
		title: "محدودیت دسترسی به صفحه",
		message: "شما مجوز لازم جهت دسترسی به این صفحه را ندارید.",
	},
	videoDownloader: {
		error: "دانلود برخی از ویدیوها ناموفق بود!",
		removeSheetTitle: "آیا ویدیوی دانلود شده حذف شود؟",
		removeSheetMessage:
			"آیا مطمئنید می خواهید این ویدیو را حذف کنید؟در صورت حذف امکان دسترسی در حالت آفلاین وجود ندارد و ممکن است بارگذاری ویدیو کندتر باشد.",
	},
	vocabList: {
		pageTitle: "لیست لغـات و اصـطلاحـات",
		knew: "بلدم",
		detailsSheetTitle: "جزییات لغت یا اصطلاح",
	},
	incompleteRegister: {
		pageTitle: "ثبت نام ناقص",
		title: "ثبت نام شما ناقص است!",
		subtitle:
			"متاسفانه به دلایل نامشخص موفق به ارسال کد تایید برای شما نشدیم!",
		yourPH: "شماره تماس شما:",
		yourEmail: "ایمیل شما:",
		wrongNumber: "آیا مطمئنید این شماره تماس صحیح است؟.",
		wrongEmail: "آیا مطمئنید این ایمیل صحیح است؟.",
		sendVerifCode: "ارسال کد تایید",
		supportMsg:
			"اگر تا ۳ بار دیگر تلاش نمودید و موفق به تایید حساب خود نشدید لطفا با تیم پشتیبانی متااورا تماس حاصل فرمایید.",
		goToSupport: "تماس با پشتیبانی",
		reRegister: "ثبت‌ نام مجدد",
		btnInfo:
			"ثبت نام شما ناقص است. لطفا برای تکمیل ثبت نام اینجا کلیک کنید",
	},
	studypoint: {
		updateError:
			"متاسفانه ذخیره پیشرفت شما در این قسمت با خطا مواجه شد! لطفا دوباره اطلاعات را ذخیره نمایید.",
	},
	notifications: {
		pageTitle: "اعلانات و تازه‌ها",
		groups: {
			Review: "مرورها",
			Streak: "زنجیره مطالعه",
			Quiz: "آزمون",
			Discount: "تخفیف",
			Speaking: "صحبت کردن",
			Listening: "شنیدن",
			Grammar: "گرامر",
			Vocabulary: "لغات و اصطلاحات",
			Reading: "خواندن",
			AppUpdate: "به‌روزرسانی اپ",
			Affiliate: "همکاری",
			Subscription: "اشتراک",
			NewContent: "تازه‌ها",
			StudyPlan: "برنامه‌ریزی مطالعه",
			Poll: "نظرسنجی",
			Admin: "مدیریت",
			AppHelpCenter: "آموزش و ترفندها",
			NewsIssues: "اخبار و اطلاعیه‌ها",
			OnlineClass: "کلاس آنلاین",
			SocialNetworks: "شبکه های اجتماعی",
			Others: "سایر موارد",
		},
	},

	chat: {
		typing: "در حال نوشتن",
	},

	textToSpeechSettings: {
		pageTitle: "تنظیمات متن به صدا",
		selectVoice: "انتخاب صدا",
	},

	cbPayment: {
		notFountError: "اشتراک پیدا نشد!",
		CommunicateError: "ارتباط با بازار قطع شده است.",
		installError: "بازار در گوشی شما نصب نیست",
		sdkError: "اس‌دی‌کا به بازار متصل نیست. لطفا به پشتیبانی اطلاع دهید.",
		cancelError: "خرید لغو شد.",
		generalError: "خرید اشتراک با خطایی مواجه شد.",
		success: "پرداخت با موفقیت انجام شد!",
		failed: "پرداخت با شکست روبرو شد!",
		waiting: ".در حال تایید پرداخت، لطفا منتظر بمانید",
		goHome: "رفتن به صفحه اصلی",
		close: "بستن",
	},
	serverNetworkProblems: {
		connectionProblem: "اتصال ناپایدار",
		unstableConnection: "اتصال اینترنت شما ناپایدار است.",
		online: "آنلاین شدید!",
		offline: "آفلاین شدید!",
		onlineMessage:
			"شما مجددا آنلاین شدید. با خیال راحت مطالعه خود را ادامه دهید.",
		offlineProblem: "لطفا اتصال اینترنت خود را بررسی نمایید.",
		serverProblem: "اختلال در سرور",
		awsProblem: "اختلال در سرور مدیا",
		serverUnreachable:
			"متاسفانه سرورهای متااورا قابل دسترس نمی‌باشند. لطفا با پشتیبانی متااورا تماس حاصل فرمایید.",
		aswUnreachable:
			"متاسفانه سرورهای چندرسانه‌ای متااورا قابل دسترس نمی‌باشند. لطفا با پشتیبانی متااورا تماس حاصل فرمایید.",
		contactSupport: "تماس با پشتیبانی",
	},
	welcomePresentPopup: {
		title: "کاربر گرامی خوش آمدید!",
		pageTitle: "به خانواده بزرگ متااورا خوش آمدید!",
		pageDescPer: "در اولین قدم می خوام",
		pageDescMain:
			"۳ روز دسترسی رایگان به تمامی دوره ها و امکانات اپ	متااورا",
		pageDescAfter: "رو بهت هدیه بدم",
		actionBtn: "دریـافـت هدیــه",
	},
};

export default fa;
